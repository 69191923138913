import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/services/Security/guard.service";
import { AppSettings } from "@app/helpers/AppSettings";
import { SearchInListComponent } from "@src/app/core/Search/search-in-list/search-in-list.component"; 
import { SubDepartamentosSettings } from "./config/sub-departamentos.settings"; 
import { SubDepartamentosSearch } from "./config/sub-departamentos.search";
import { SubDepartamentosComponent } from "./components/sub-departamentos.component";

const routes: Routes = [
  {
    path: "listado",
    component: SearchInListComponent,
    data: { title: "Departamentos Listado" },
  },
  { path: "", component: SubDepartamentosComponent },
  { path: ":id", component: SubDepartamentosComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubDepartamentosRoutingModule {
 
  //Routing principal
  public static RoutingDepartamentos = {
    path: "nomina/sub-departamentos",
    loadChildren: () =>
      import("./sub-departamentos.module").then(
        (m) => m.SubDepartamentosModule
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Sub Departamentos`,
      icon: "fa-light fa-list-tree",
      setting: SubDepartamentosSettings,
      search : SubDepartamentosSearch
    },
  };
}
 